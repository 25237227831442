<template>
  <div>
    <flex-slider :slides="[require('@/assets/82.jpg'), require('@/assets/108.jpg')]" :height="450"></flex-slider>
    <v-page id="contact" no-auto-height class="d-flex" style="padding-top: 50px;padding-bottom: 75px;">
      <v-container>
        <v-row>
          <v-col class="text-center"><h1 class="dz" style="font-size: 4rem;">{{ $t("contact.getintouch") }}</h1></v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <getintouch-card icon="mdi mdi-map-marker" :title="$t('contact.onsadres')" :action-text="$t('contact.route')"  href="https://goo.gl/maps/gkwzBogSdwg6ATKZ7" target="_blank">
              Voorstraat 45<br/>3231 BE Brielle
            </getintouch-card>
          </v-col>
          <v-col cols="4">
            <getintouch-card icon="mdi mdi-email" :title="$t('contact.contactinfo')" :action-text="$t('contact.belons')"  href="tel:31181725980">
              +31 (0)181 725 980<br/>
              info@poorterhotel.nl
            </getintouch-card>
          </v-col>
          <v-col cols="4">
            <getintouch-card icon="mdi mdi-account-multiple-outline" :title="$t('contact.frontdesk')" :action-text="$t('contact.mailons')" :to="{name: 'Contact', hash: '#contactform'}">
              info@poorterhotel.nl<br/>
              sales@poorterhotel.nl
            </getintouch-card>
          </v-col>
        </v-row>
        <div id="contactform"></div>
      </v-container>
    </v-page>
    <v-page background-color="#282828" class="py-16" :background="require('@/assets/watermerk.svg?fill=#1F1F1F')" dark style="background-size: 100%;background-position:center;" no-auto-height>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" md="5">
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 4rem;">
                <google-maps api-key="AIzaSyBrvRwQu21ap_clGYkKof4BwrRG-bQFtXA" :map-config="mapConfig">
                  <template slot-scope="{ google, map }">
                    <map-marker v-for="marker in markers" :key="marker" :google="google" :map="map" :marker="marker"/>
                  </template>
                </google-maps>
              </h1></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="7">

            <v-form ref="contactform" @submit.prevent="send">
              <v-text-field
                  :rules="[v => !!v || 'Item is required']"
                  :label="`${$t('contact.naam')} *`"
                  v-model="naam"
                  required
              ></v-text-field>
              <v-text-field
                  :rules="[v => !!v || 'Item is required']"
                  :label="`${$t('contact.email')} *`"
                  v-model="email"
                  required
              ></v-text-field>
              <v-text-field
                  :rules="[v => !!v || 'Item is required']"
                  :label="`${$t('contact.telefoon')} *`"
                  v-model="telefoon"
                  required
              ></v-text-field>
              <v-textarea
                  :rules="[v => !!v || 'Item is required']"
                  :label="`${$t('contact.bericht')} *`"
                  v-model="bericht"
                  auto-grow
              ></v-textarea>
              <v-alert color="success" v-if="mailsent">Uw bericht is verzonden!</v-alert>
              <v-btn
                  class="mt-4 rounded-0"
                  type="submit"
                  color="primary"
                  style="color: black; font-weight: bold"
              >
                {{$t('contact.verstuur')}}
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import GetintouchCard from "@/components/GetintouchCard";
import FlexSlider from "@/components/FlexSlider";
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";
import GoogleMaps from "@/components/GoogleMaps";
import MapMarker from "@/components/MapMarker";
import axios from "../http";

export default {
name: "Contact",
  components: {GetintouchCard, FlexSlider, VPage, Footer, GoogleMaps, MapMarker},
  methods: {
    async send() {
      if (this.$refs.contactform.validate()) {
        await axios.post('/contact', {
          naam: this.naam,
          email: this.email,
          telefoon: this.telefoon,
          bericht: this.bericht
        });
        this.$refs.contactform.reset();
        this.mailsent = true;
      }
    }
  },
  data: () => ({
    naam: "",
    email: "",
    telefoon: "",
    bericht: "",
    mailsent: false,
    mapConfig: { //52.2093662,4.1585425
      center: { lat: 51.90204385533437, lng: 4.164735438432087 },
      zoom: 16,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        }
      ]
    },
    markers: [
      {
        position: { lat: 51.9035201, lng: 4.165080 },
      },
      {
        position: { lat: 51.903203508992966, lng: 4.163932686953423},
        icon: require('@/assets/p1.png')
      },
      {
        position: { lat: 51.90375457938471, lng: 4.164158232941996},
        icon: require('@/assets/electric-parking.png')
      },
      {
        position: { lat: 51.90219544119693, lng: 4.166493826858695},
        icon: require('@/assets/electric-parking.png')
      },
      {
        position: { lat: 51.90284115364785, lng: 4.166701329168183},
        icon: require('@/assets/p2.png')
      },
      {
        position: { lat: 51.899690434362704, lng: 4.169642448859176},
        icon: require('@/assets/p3.png')
      }
    ]
  })
}
</script>

<style scoped>

</style>
