<template>
  <v-carousel v-model="model" :height="height" cycle hide-delimiter-background>
    <v-carousel-item
        v-for="(item, itemIdx) in slides"
        :key="itemIdx"
        :src="item"
    >
      <template slot="default">

      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "FlexSlider",
  props: {
    slides: {
      type: Array
    },
    height: {
      type: Number,
      default: 750
    }
  },
  data() {
    return {
      model: 0,
    }
  }
}
</script>

<style scoped>
.maintext {
  font-family: "DeathZone", sans-serif;
  font-weight: 400;
}
</style>